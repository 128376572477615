.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 50px;
  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  /* composes: formMargins; */
  margin-top: 50px;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.profileForm {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.uploadbtn {
  height: 40px;
  margin-top: 40px;
  margin-left: 20px;
  background-color: #f3f6f9;
  border: solid;
  border-radius: 5px;
  border-color: lightgray;
  width: 150px;
}
.test {
  padding: 5px;
}
.outerbox {
  margin-top: 10px;
  margin-right: 5px;
  background: #ffffff;
  border: 1px solid #dde0e5;
  box-sizing: border-box;
  border-radius: 10px;

  width: 500px;
  height: 600px;
}

.innerbox {
  background: #ffffff;
  border: 1px solid #dde0e5;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  width: 475px;
  height: 580px;
}
.finput {
  background: #ffffff;
  border: 1px solid #dde0e5;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 15px;
  text-decoration: none;
  padding: 10px;
  /* font-weight: lighter; */
}
.finput:hover {
  border-color: lightgray;
}
.finput:focus {
  border-color: lightgray;
}

.char {
  position: right;
  margin-left: 300px;
  /* width: 182.4px;
      height: 12.9px; */
  /* left: 540.45px;
      top: 656.53px; */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #adadad;
}
.savebtn {
  border: 1px solid;
  border-radius: 10px;
  background-color: #60ca72;
  box-sizing: border-box;
}
.cancel {
  color: #6e9ae5;
}
.savebtn:hover {
  background-color: #60ca72;
}
.btn {
  display: flex;
  margin-top: 30px;
}
.btns {
  background-color: #ffffff;
  border: solid;
  border-radius: 5px;
  border-color: lightgray;
  color: rgb(117, 117, 117);
  padding: 5px 10px;
  text-decoration: none;
  margin-right: 30px;
  width: 160px;
  font-size: 12px;
}
.submitButton {
  margin-top: 100px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 150px;
  }
}
.label {
  margin-bottom: 1rem;
}
.text {
  color: gray;
}
.inlineField {
  display: flex;
}

.circle {
  /* position: absolute; */
  width: 120px;
  height: 120px;
  margin-left: 10px;
  margin-bottom: 40px;
  /* left: 337.23px;
      top: 198.22px; */
  font-size: 10px;
  background: #ffffff;
  border: 1px dashed #c4c4c4;
  box-sizing: border-box;
  border-radius: 200px;
  text-align: center;
}
.circle > span {
  margin-top: 50px;
  color: rgb(117, 117, 117);
}
