.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.description {
  composes: formMargins;
  flex-shrink: 0;
  flex: 1;
  /* max-width: 100%; */
}

.title {
  composes: formMargins;
}

.policy {
  composes: formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.FieldArray {
  display: flex;
  position: relative;
}
.close {
  cursor: pointer;
  position: absolute;
  font-size: 19px;
  color: red !important;
  right: 2%;
  border: 1px solid #ddd;
  width: 30px;
  top: 19%;
  transform: translate(0, -50%);
  height: 30px;
  padding: 0px 7px;
  border-radius: 50%;
  @media (max-width: 720px) {
    padding: 3px 7px;
  }

  /* transform: translate(-519%, -231%); */
  &:hover {
    background-color: #eee;
    border: 1px solid whitesmoke;
  }
}
.condition {
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
  padding-bottom: 2px;
  font-size: 14px;
}
.addButton {
  display: inline-block;
  border-radius: 999px;
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border 200ms ease, background-color 200ms ease;
  margin-bottom: 1rem;
  &:focus {
    outline: none !important;
  }
  &:hover {
    box-shadow: whitesmoke;
  }
}
.buttons {
  margin-top: 5rem;
}
