@import '../../styles/propertySets.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;
  height: unset !important;
  min-width: unset;

  @media (--viewportMedium) {
    flex-basis: 576px;
    /* height: 100%; */
    height: 90ch;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 2rem 0.75rem;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(13 13 13 / 20%);
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
    min-width: 400px;
  }
}
.modalContentContainer {
  @media (--viewportMedium) {
    border: 1px solid rgb(13 13 13 / 70%);
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
  }
  @media (--viewportLarge) {
    border: none;
    border-radius: 0;
  }
}
.modalHeading {
  /* margin-top: 89px;
  margin-bottom: 36px; */
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 30px 0;
    text-align: center;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
    margin-left: 6px;
  }
}

.bookingTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Mobile phones introduce bottom-bar, which takes 102px vertical space.
     In addition to that we have fixed button that takes 84px vertical space.
  */
  margin: 0 0 186px 0;
  padding: 0 2rem;
  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    padding: 0 1rem 2rem 1rem;

    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
    padding: 1rem;
    padding-top: 0;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  align-items: center;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.enquireButtonContainer {
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.enquireButtonContainer button {
  background-color: hsl(0deg 0% 5% / 75%);
  background-color: var(--marketplaceColor);
  &:hover {
    background-color: var(--marketplaceColorDark);
  }

  padding: 0 35px;
}
.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}
.actionButtonsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mobileEnquireContainer:not(:last-child) {
  margin-bottom: 1rem;
}
.mobileEnquireContainer:not(:last-child) > button {
  background-color: hsl(0deg 0% 5% / 75%);
  background-color: var(--marketplaceColor);
  color: white;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.radioButtonContainer {
  & div:nth-child(2) {
    height: unset;
    width: unset;
  }
  & [data-id='radio-button-group_radio-container'] {
    height: 2.5rem;
  }
  & label[for] {
    height: 100%;
    width: 100%;
    align-items: center;
  }
}

.buttonsContainer {
  padding: 0 1rem;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}
#duration > legend {
  text-align: center;
}

.bookingPanelHeading {
  text-align: center;
  padding: 0.8rem 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-top: 0.9rem;
  font-weight: bold;
  @media (--viewportMedium) {
    background: rgba(13, 13, 13, 0.702);
    color: white;
    margin-bottom: 1.5rem;
    font-weight: normal;
    margin-top: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}
.DayPicker button {
  width: 9px;
}
