/*
If you need to add css to the Logo you can do it here.

.logoMobile {
}
*/
.logoDesktop {
  margin: 0px 20px;

  font-size: 2rem;
  font-weight: 700;
}

.footerLogo {
  margin: 0 !important;
}
