*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.top-0 {
  top: 0px
}

.right-0 {
  right: 0px
}

.top-2\/3 {
  top: 66.666667%
}

.left-1\/2 {
  left: 50%
}

.top-1\/2 {
  top: 50%
}

.top-3 {
  top: 0.75rem
}

.right-4 {
  right: 1rem
}

.right-\[1rem\] {
  right: 1rem
}

.z-\[0\] {
  z-index: 0
}

.z-\[1\] {
  z-index: 1
}

.-z-10 {
  z-index: -10
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.m-0 {
  margin: 0px
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-0 {
  margin-bottom: 0px
}

.mt-0 {
  margin-top: 0px
}

.mt-4 {
  margin-top: 1rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mt-2 {
  margin-top: 0.5rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mt-8 {
  margin-top: 2rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.ml-6 {
  margin-left: 1.5rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mt-5 {
  margin-top: 1.25rem
}

.ml-4 {
  margin-left: 1rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-full {
  height: 100%
}

.h-24 {
  height: 6rem
}

.h-\[60px\] {
  height: 60px
}

.h-\[500px\] {
  height: 500px
}

.h-7 {
  height: 1.75rem
}

.h-64 {
  height: 16rem
}

.w-\[90\%\] {
  width: 90%
}

.w-full {
  width: 100%
}

.w-24 {
  width: 6rem
}

.w-\[170px\] {
  width: 170px
}

.w-7 {
  width: 1.75rem
}

.w-64 {
  width: 16rem
}

.max-w-3xl {
  max-width: 48rem
}

.max-w-5xl {
  max-width: 64rem
}

.max-w-\[120px\] {
  max-width: 120px
}

.max-w-\[300px\] {
  max-width: 300px
}

.max-w-xl {
  max-width: 36rem
}

.flex-1 {
  flex: 1 1 0%
}

.grow {
  flex-grow: 1
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-2\/3 {
  --tw-translate-y: -66.666667%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.resize-none {
  resize: none
}

.resize {
  resize: both
}

.appearance-none {
  appearance: none
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-8 {
  gap: 2rem
}

.gap-4 {
  gap: 1rem
}

.gap-x-6 {
  column-gap: 1.5rem
}

.gap-y-2 {
  row-gap: 0.5rem
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.overflow-hidden {
  overflow: hidden
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.break-all {
  word-break: break-all
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-xl {
  border-radius: 0.75rem
}

.rounded {
  border-radius: 0.25rem
}

.rounded-tl-\[99999px\] {
  border-top-left-radius: 99999px
}

.rounded-bl-\[99999px\] {
  border-bottom-left-radius: 99999px
}

.border {
  border-width: 1px
}

.border-\[2px\] {
  border-width: 2px
}

.border-0 {
  border-width: 0px
}

.border-2 {
  border-width: 2px
}

.border-b-\[2px\] {
  border-bottom-width: 2px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-none {
  border-style: none
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity))
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity))
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.border-marketplaceColor {
  --tw-border-opacity: 1;
  border-color: rgb(0 149 179 / var(--tw-border-opacity))
}

.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgb(250 204 21 / var(--tw-border-opacity))
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity))
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity))
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity))
}

.bg-black\/50 {
  background-color: rgb(0 0 0 / 0.5)
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-marketplaceColor {
  --tw-bg-opacity: 1;
  background-color: rgb(0 149 179 / var(--tw-bg-opacity))
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.bg-black\/30 {
  background-color: rgb(0 0 0 / 0.3)
}

.fill-pink-500 {
  fill: #ec4899
}

.object-cover {
  object-fit: cover
}

.p-4 {
  padding: 1rem
}

.p-3 {
  padding: 0.75rem
}

.p-24 {
  padding: 6rem
}

.p-2 {
  padding: 0.5rem
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.pt-0 {
  padding-top: 0px
}

.pb-0 {
  padding-bottom: 0px
}

.pt-2 {
  padding-top: 0.5rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.text-center {
  text-align: center
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1
}

.font-semibold {
  font-weight: 600
}

.font-extrabold {
  font-weight: 800
}

.font-bold {
  font-weight: 700
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.italic {
  font-style: italic
}

.leading-\[1\.4\] {
  line-height: 1.4
}

.leading-snug {
  line-height: 1.375
}

.leading-normal {
  line-height: 1.5
}

.tracking-wider {
  letter-spacing: 0.05em
}

.tracking-wide {
  letter-spacing: 0.025em
}

.tracking-normal {
  letter-spacing: 0em
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity))
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity))
}

.text-marketplaceColor {
  --tw-text-opacity: 1;
  color: rgb(0 149 179 / var(--tw-text-opacity))
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity))
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.opacity-100 {
  opacity: 1
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.outline {
  outline-style: solid
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-100 {
  transition-duration: 100ms
}

.duration-200 {
  transition-duration: 200ms
}

.duration-150 {
  transition-duration: 150ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.focus-within\:border-gray-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.focus-within\:text-gray-700:focus-within {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.hover\:cursor-pointer:hover {
  cursor: pointer
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity))
}

.hover\:border-marketplaceColorDark:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 106 128 / var(--tw-border-opacity))
}

.hover\:border-yellow-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity))
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity))
}

.hover\:bg-marketplaceColorDark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 106 128 / var(--tw-bg-opacity))
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity))
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.hover\:text-marketplaceColorDark:hover {
  --tw-text-opacity: 1;
  color: rgb(0 106 128 / var(--tw-text-opacity))
}

.hover\:no-underline:hover {
  text-decoration-line: none
}

.hover\:opacity-\[\.9\]:hover {
  opacity: .9
}

.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none
}

.disabled\:border-gray-400:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.disabled\:bg-gray-400:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity))
}

.disabled\:bg-gray-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}

.group:hover .group-hover\:border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.group:hover .group-hover\:text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity))
}

@media (min-width: 640px) {
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px
  }

  .sm\:w-\[80\%\] {
    width: 80%
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  .sm\:shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }
}

@media (min-width: 768px) {
  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .md\:gap-8 {
    gap: 2rem
  }

  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
  }
}

@media (min-width: 1024px) {
  .lg\:top-3\/4 {
    top: 75%
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .lg\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:gap-12 {
    gap: 3rem
  }

  .lg\:place-self-end {
    place-self: end
  }

  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px
  }
}