/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
  border: none !important;
  padding: 0 1rem;
}

.root {
  border: 1px solid hsl(0, 0%, 83%);
  border-radius: 100vmax;
  display: flex;
  align-items: center;
  padding: 0.125rem;
  & > button[role='button'] {
    /* padding: 0 0.75rem; */
    width: 40px !important;
    height: 36px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 1.5rem;
    background: hsl(0, 0%, 93%);
    border-radius: 50%;
    color: rgb(82, 179, 239);
    user-select: none;
    &:not(:disabled):hover {
      cursor: pointer;
      background: hsl(0, 0%, 87%);
    }
    &:disabled {
      color: darkgray;
      pointer-events: none;
    }
  }
}
