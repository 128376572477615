.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 50px;
  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 38px;
  }
}

.title {
  composes: formMargins;

  margin-top: 50px;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.farry_contain {
  width: 100%;
}
.farry {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 30%; /*grow | shrink | basis */
}
.test {
  padding: 5px;
}
.finput {
  /* margin-top: 30px; */
  background-color: #ffffff;
  border: solid;
  border-radius: 0px 5px 5px 0px;
  border-width: 1px;
  border-color: #cbd8e3;
  color: rgb(117, 117, 117);
  /* padding: 10px 25px; */
  text-decoration: none;
  /* font-size: 10px; */
  width: 100px;
}

.year {
  background-color: #ffffff;
  border: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: #cbd8e3;
  color: rgb(117, 117, 117);
  padding: 9px;
  text-decoration: none;
  text-align: center;
  width: 80px;
}
.year:hover {
  border-color: #cbd8e3;
}
.year:focus {
  border-color: #cbd8e3;
}
.fspan {
  background-color: #f3f6f9;
  border: solid;
  border-radius: 5px 0px 0px 5px;

  /* border-radius: 5px; */
  border-color: lightgray;
  border-width: 1px;
  color: rgb(117, 117, 117);
  padding: 5px;
  border-right-width: 0;

  text-decoration: none;
  text-align: center;
  width: 60px;
}

.btns {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}
.btn {
  background-color: #f3f6f9;
  border: solid;
  border-radius: 5px;
  border-color: #cbd8e3;
  color: rgb(117, 117, 117);
  border-width: 1px;
  padding: 5px 10px;
  text-decoration: none;
  margin-right: 30px;
  width: 160px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
}
.submitButton {
  margin-top: 100px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 150px;
  }
}
.label {
  margin-bottom: 1rem;
}
.text {
  color: gray;
}
.inlineField {
  display: flex;
}
.finput:hover {
  border-color: lightgray;
}
.finput:focus {
  border-color: lightgray;
}
