.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.description {
  composes: formMargins;
  flex-shrink: 0;
}

.title {
  composes: formMargins;
}
.datePicker {
  composes: formMargins;
  padding-top: 20px;
}

.policy {
  composes: formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.openingHour {
  position: relative;
  z-index: 999;
  display: flex;
  margin-bottom: 12px;

  & > * {
    flex: 1;
  }
}
.message {
  color: var(--failColor);
  margin-top: -30px;
  margin-bottom: 30px;
}
.close {
  cursor: pointer;
  position: absolute;
  font-size: 46px;
  color: red !important;
  right: 0%;
  padding: 0px 1px;
  transform: translate(-151%, 58%);
}
.dateTime {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.level {
  width: 40%;
  margin-right: 20px;
}
.buttons {
  margin-top: 5rem;
}
