.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.description {
  composes: formMargins;
  flex-shrink: 0;
}

.title {
  composes: formMargins;
}

.policy {
  composes: formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  @media (max-width: 760px) {
    margin-top: 40px;
  }
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.openingHour {
  position: relative;
  z-index: 999;
  display: flex;
  margin-bottom: 12px;

  & > * {
    flex: 1;
  }
}
.message {
  color: var(--failColor);
}
.close {
  cursor: pointer;
  position: absolute;
  font-size: 33px;
  color: red !important;
  width: 40px;
  height: 40px;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 4px 7px;
  transform: translate(105%, 78%);
  @media (max-width: 780px) {
    width: 30px;
    height: 30px;
    padding: 3px 3px;
    transform: translate(35%, 69%);
  }
  @media (min-width: 780px) {
    width: 35px;
    height: 35px;
    padding: 2px 5px;
    transform: translate(23%, 97%);
  }
  &:hover {
    background-color: #eee;
    border: 1px solid whitesmoke;
  }
}

.FieldArray {
  display: flex;
  margin-bottom: 10px;
  padding-right: 13px;
}
.level {
  width: 48%;
  margin-right: 5px;
}
.message {
  color: red;
}
.addButton {
  display: inline-block;
  /* max-width: 200px; */
  /* border: 1px solid var(--matterColorAnti); */
  border-radius: 999px;
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none !important;
  box-shadow: none;
  /* margin-top: 1rem; */
  transition: border 200ms ease, background-color 200ms ease;
  margin-bottom: 1rem;
  &:focus {
    /* box-shadow: none; */
    outline: none !important;
  }
  &:hover {
    /* background-color: #eee; */
    box-shadow: whitesmoke;
    /* border: 1px solid whitesmoke; */
  }
}
.buttonContainer {
  display: flex;
}
.buttons {
  margin-top: 5rem;
}
.backIcon {
  margin-right: 1rem;
  font-size: 3rem;
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
