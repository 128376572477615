.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
  padding-top: 12px;
  height: 18px;
}
.selectOptions {
  z-index: 50;
}

.newInput {
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 0 5px 0;

  /* Borders */
  border: none;
  border-width: 2px;
  border-style: solid;
  border-color: var(--marketplaceColor);
  border-radius: 0;
  border-color: var(--attentionColor);

  &::placeholder {
    color: var(--matterColorAnti);
  }

  /* Effects */

  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {
    border-color: var(--matterColor);
    outline: none;
  }

  @media (--viewportMedium) {
    padding: 4px 0 2px 0;
  }
}

.newInputSuccess {
  border-color: var(--successColor);
}

.newInputError {
  border-color: var(--failColor);
}
