@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';
/* Animation for the hero elements */
@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* @apply --backgroundImage; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;
  margin-top: 20%;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
    margin-top: 6rem;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
    margin-top: 6rem;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    margin-top: 6rem;
    /* max-width: calc(1052px + 36px + 36px); */
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  font-size: 4rem;

  letter-spacing: 1px;
  line-height: 1;
  /* text-shadow: 2px 2px 4px black; */

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    font-size: 5rem;

    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  /* text-shadow: 2px 2px 4px black; */
  font-weight: bold;
  font-size: 1.75rem;
  letter-spacing: 0.5px;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.items {
  /* min-height: 600px; */
  max-height: 600px;
  object-fit: cover;
  object-position: center;
  height: 600px;
  width: 100vw;
  @media (max-width: 770px) {
    height: 600px;
  }

  /* & img {
    display: block;
    height: 100vh;
    width: 100%;
  } */
}

.testimonial {
  /* "absolute bottom-80 right-20 z-10 py-12" */
  position: absolute;
  bottom: 20rem;
  right: 2rem;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); */
  max-width: 384px;

  @media (max-width: 768px) {
    bottom: 10rem;
    right: unset;
    left: 2rem;
  }

  @media (min-width: 769px) and (max-width: 1000px) {
    bottom: 8rem;
    right: unset;
    left: 5rem;
    max-width: 600px;
  }
  & p {
    line-height: 1.2;
    margin-bottom: 0;
  }
  & small {
  }
}
